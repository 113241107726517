import * as React from "react";
import styled from "styled-components";
import { heights } from "../styles/variables";

interface LayoutMainProps {
  className?: string;
  children: React.ReactNode;
}

const StyledLayoutMain = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${heights.nav}px;
  background: white;
`;

const LayoutMain: React.FC<LayoutMainProps> = ({ children, className }) => (
  <StyledLayoutMain className={className}>{children}</StyledLayoutMain>
);

export default LayoutMain;
