import React from "react";
import styled from "styled-components";
import { spacers, breakpoints } from "../styles/variables";

interface HorizontalWrapperProps {
  children: React.ReactNode;
}

const WrapperDiv = styled.div`
  width: 100%;
  padding: 0 ${spacers.s}px;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;

  @media screen AND (min-width: ${breakpoints.m}px) {
    padding: 0 ${spacers.m}px;
  }

  @media screen AND (min-width: ${breakpoints.l}px) {
    padding: 0 ${spacers.l}px;
  }
`;

const HorizontalWrapper: React.FC<HorizontalWrapperProps> = ({ children }) => (
  <WrapperDiv>{children}</WrapperDiv>
);

export default HorizontalWrapper;
