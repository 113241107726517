import * as React from "react";
import styled from "styled-components";

import "../styles/normalize.css";
import "../styles/fonts.css";
import "../styles/base.css";

interface LayoutRootProps {
  className?: string;
  children: React.ReactNode;
}

const StyledLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const LayoutRoot: React.FC<LayoutRootProps> = ({ children, className }) => (
  <>
    <StyledLayoutRoot className={className}>{children}</StyledLayoutRoot>
  </>
);

export default LayoutRoot;
