import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

interface NavLinkProps {
  uri: string;
  children: React.ReactNode;

  navIsOpen: boolean;
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LinkElem = styled(Link)`
  font-size: 20px;
  line-height: 40px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  hyphens: auto;
  word-wrap: break-word;

  &:hover {
    text-decoration: underline;
  }
`;

const NavLink: React.FC<NavLinkProps> = ({
  uri,
  children,
  navIsOpen,
  setNavIsOpen,
}) => (
  <LinkElem to={uri} onClick={() => setNavIsOpen(!navIsOpen)}>
    {children}
  </LinkElem>
);

export default NavLink;
