import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import "normalize.css";

import Header from "../components/Header";
import LayoutRoot from "../components/LayoutRoot";
import LayoutMain from "../components/LayoutMain";
import Footer from "../components/Footer";

const DefaultLayout: React.FC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query DefaultLayoutQuery {
        cms {
          entry(type: "sitedata") {
            ... on CMS_andereInhalte_sitedata_Entry {
              seitenBeschreibung
            }
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <LayoutRoot>
        <Helmet>
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/assets/favicon/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/assets/favicon/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/assets/favicon/favicon-16x16.png"
          />
          <link rel="manifest" href="/assets/favicon/site.webmanifest" />
          <link
            rel="mask-icon"
            href="/assets/favicon/safari-pinned-tab.svg"
            color="#5bbad5"
          />
          <link rel="shortcut icon" href="/assets/favicon/favicon.ico" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta
            name="msapplication-config"
            content="/assets/favicon/browserconfig.xml"
          />
          <meta name="theme-color" content="#ffffff"></meta>
          <meta
            name="description"
            content={data.cms.entry.seitenBeschreibung}
          />
        </Helmet>
        <Header title="Niederegger AG" />
        <LayoutMain>{children}</LayoutMain>
        <Footer />
      </LayoutRoot>
    )}
  />
);

export default DefaultLayout;
