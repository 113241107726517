import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import HorizontalWrapper from "./HorizontalWrapper";
import { colors, heights, breakpoints } from "../styles/variables";
import Toggle from "./navigation/Toggle";
import Navigation from "./navigation/Navigation";

const StyledHeader = styled.header`
  background-color: ${colors.brand};
  height: ${heights.nav}px;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  > div:first-of-type {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:first-of-type {
      background-color: ${colors.brand};
      z-index: 11;
    }

    > a > img {
      max-width: 200px;
      z-index: 10;

      @media screen AND (min-width: ${breakpoints.m}px) {
        max-width: 331px;
      }
    }
  }
`;

const Header: React.FC = () => {
  const [navIsOpen, setNavIsOpen] = React.useState(false);
  return (
    <StyledHeader>
      <HorizontalWrapper>
        <Link className="vertCenter" to="/">
          <img src="/assets/logos/logo.svg" />
        </Link>
        <div className="vertCenter">
          <Toggle navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen} />
        </div>
      </HorizontalWrapper>
      {/* <HomepageLink to="/">{title}</HomepageLink> */}
      <Navigation navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen} />
    </StyledHeader>
  );
};

export default Header;
