import React from "react";
import styled from "styled-components";
import NavLink from "./NavLink";

interface SectionLinkListProps {
  data: [
    {
      title: string;
      uri: string;
    }
  ];
  navIsOpen: boolean;
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const SectionLinkList: React.FC<SectionLinkListProps> = ({
  data,
  setNavIsOpen,
  navIsOpen,
}) => (
  <List>
    {data.map((link) => (
      <NavLink
        key={link.title}
        uri={"/" + link.uri}
        navIsOpen={navIsOpen}
        setNavIsOpen={setNavIsOpen}
      >
        {link.title}
      </NavLink>
    ))}
  </List>
);

export default SectionLinkList;
