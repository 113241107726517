import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

interface TitleLinkProps {
  children: React.ReactNode;
  uri: string;
  navIsOpen: boolean;
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TitleElem = styled(Link)`
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  hyphens: auto;
  word-wrap: break-word;

  &:hover {
    text-decoration: underline;
  }
`;

const TitleLink: React.FC<TitleLinkProps> = ({
  children,
  uri,
  navIsOpen,
  setNavIsOpen,
}) => {
  const [link, setLink] = useState("#");

  useEffect(() => {
    if (uri == "startseite") {
      setLink("/");
    } else if (uri == "ueberuns") {
      setLink("/ueberuns/leitbild");
    } else {
      setLink("/" + uri);
    }
  }, [uri]);

  return (
    <TitleElem
      className="h3"
      to={link}
      onClick={() => setNavIsOpen(!navIsOpen)}
    >
      {children}
    </TitleElem>
  );
};

export default TitleLink;
