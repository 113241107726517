import React, { useEffect } from "react";
import styled from "styled-components";
import { colors, heights, breakpoints } from "../../styles/variables";
import NavGrid from "./NavGrid";
import GridSection from "./GridSection";
import Wrapper from "../Wrapper";
import { useStaticQuery, graphql } from "gatsby";

interface NavigationProps {
  navIsOpen: boolean;
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Nav = styled.nav`
  margin-top: -100vh;
  position: fixed;
  width: 100vw;
  height: calc(100vh - ${heights.nav}px);
  background: ${colors.brand};
  transition: margin-top 300ms ease 300ms;
  z-index: 9;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  > div:first-of-type {
    position: relative;

    @media screen AND (min-width: ${breakpoints.m}px) {
      height: calc(100vh - ${heights.nav}px);
    }
  }

  > div:nth-of-type(2) {
    position: relative;
    width: 100%;
    //bottom: 0;
    display: none;
  }

  &.navIsOpen {
    transition: margin-top 300ms ease 0ms;
    margin-top: 0;

    > div:nth-of-type(2) {
      display: block;
    }
  }
`;

const Navigation: React.FC<NavigationProps> = ({ navIsOpen, setNavIsOpen }) => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        entries(section: "Inhalt", level: 1) {
          title
          uri
          children(type: "fachbereich, leitbild, team, dieNiedereggerAg") {
            title
            uri
          }
        }
      }
    }
  `);

  useEffect(() => {
    const navLinks = document.querySelectorAll("nav a");
    navLinks.forEach((link) => {
      link.getAttribute("href") == window.location.pathname
        ? (link.style.textDecoration = "underline")
        : "";
    });
    if (navIsOpen) {
      document.querySelector("body").style.overflowY = "hidden";
    } else {
      document.querySelector("body").style.overflowY = "scroll";
    }
  });

  return (
    <Nav className={navIsOpen ? "navIsOpen" : ""}>
      <Wrapper>
        <NavGrid navIsOpen={navIsOpen}>
          {data.cms.entries.map((entry) => (
            <GridSection
              key={entry.uri}
              data={entry}
              setNavIsOpen={setNavIsOpen}
              navIsOpen={navIsOpen}
            />
          ))}
        </NavGrid>
      </Wrapper>
    </Nav>
  );
};

export default Navigation;
