/* eslint-disable max-len */

export const colors = {
  brand: "#3A5069",
  text: "#0E131A",
  transparentBg: "rgba(58, 80, 105, 0.8)",
  white: "#fff",
  black: "#000",
};

export const breakpoints = {
  m: 768,
  l: 1280,
  xl: 1920,
};

export const widths = {
  md: 720,
  lg: 960,
  xl: 1140,
};

export const spacers = {
  s: 20,
  m: 35,
  l: 60,
  xl: 90,
};

export const heights = {
  nav: 70,
};

export const imgix = {
  baseParams: {
    auto: "format,compress",
  },
  gridImgParams: {
    auto: "format,compress",
    fit: "crop",
    crop: "entropy",
    q: 20,
    w: 800,
    h: 600,
  },
  fullHeaderImgParams: {
    auto: "format,compress",
    fit: "crop",
    crop: "edges",
    q: 20,
    w:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 768
          : window.innerWidth < 1280
          ? 1280
          : window.innerWidth >= 1280
          ? 1920
          : 1920
        : 1920,
    h:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 480
          : window.innerWidth < 1280
          ? 800
          : window.innerWidth >= 1280
          ? 1200
          : 1200
        : 1200,
  },
  partialHeaderImgParams: {
    auto: "format,compress",
    fit: "crop",
    crop: "edges",
    q: 20,
    w:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 768
          : window.innerWidth < 1280
          ? 1280
          : window.innerWidth >= 1280
          ? 1920
          : 1920
        : 1920,
    h:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 384
          : window.innerWidth < 1280
          ? 640
          : window.innerWidth >= 1280
          ? 960
          : 960
        : 960,
  },
  teamImg: {
    auto: "format,compress",
    fit: "crop",
    crop: "faces",
    q: 20,
  },
};
