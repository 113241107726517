import React from "react";
import styled from "styled-components";
import { spacers, breakpoints } from "../styles/variables";

interface WrapperProps {
  children: React.ReactNode;
}

const WrapperDiv = styled.div`
  width: 100%;
  padding: ${spacers.s}px;
  max-width: 1440px;
  margin: 0 auto;

  @media screen AND (min-width: ${breakpoints.m}px) {
    padding: ${spacers.m}px;
  }

  @media screen AND (min-width: ${breakpoints.l}px) {
    padding: ${spacers.l}px;
  }
`;

const Wrapper: React.FC<WrapperProps> = ({ children }) => (
  <WrapperDiv>{children}</WrapperDiv>
);

export default Wrapper;
