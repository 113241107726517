import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/variables";

interface ToggleProps {
  navIsOpen: boolean;
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToggleDiv = styled.div`
  width: 70px;
  height: 40px;
  position: relative;
  cursor: pointer;
  z-index: 11;

  > div {
    z-index: 11;
    width: 70px;
    height: 6px;
    position: absolute;
    background: white;
    transition: transform 0.2s ease 0s, top 0.3s ease-in-out 0.3s;
    //transition-delay: transform 300ms;
  }

  > div:nth-of-type(1) {
    top: 0px;
    z-index: 12;
  }
  > div:nth-of-type(2) {
    transition: all 300ms;
    top: 17px;
  }
  > div:nth-of-type(3) {
    top: 34px;
    z-index: 12;
  }

  &.navIsOpen {
    > div {
      top: 17px;
    }
    > div:nth-of-type(2) {
      transition: all 300ms;
      background: ${colors.brand};
    }
    > div:nth-of-type(1) {
      transform: rotate(30deg);
    }

    > div:nth-of-type(3) {
      transform: rotate(-30deg);
    }

    > div {
      transition: top 0.2s ease 0s, transform 0.3s ease-in-out 0.3s;
    }
  }
`;

const Toggle: React.FC<ToggleProps> = ({ navIsOpen, setNavIsOpen }) => (
  <ToggleDiv
    onClick={() => setNavIsOpen(!navIsOpen)}
    className={navIsOpen ? "navIsOpen" : ""}
  >
    <div></div>
    <div></div>
    <div></div>
  </ToggleDiv>
);

export default Toggle;
