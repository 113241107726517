import React from "react";
import styled from "styled-components";
import { spacers, breakpoints } from "../../styles/variables";

interface NavGridProps {
  navIsOpen: boolean;
  children: React.ReactNode;
}

const GridDiv = styled.nav`
  opacity: 0;
  width: 100%;
  transition: opacity 200ms 0s;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${spacers.s}px;

  &.navIsOpen {
    opacity: 1;
    transition: opacity 200ms 400ms;
  }

  @media screen AND (min-width: ${breakpoints.m}px) {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-gap: ${spacers.m}px;
  }

  @media screen AND (min-width: ${breakpoints.l}px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: ${spacers.l}px;
  }
`;

const NavGrid: React.FC<NavGridProps> = ({ children, navIsOpen }) => (
  <GridDiv className={navIsOpen ? "navIsOpen" : ""}>{children}</GridDiv>
);

export default NavGrid;
