import React from "react";
import styled from "styled-components";
import { colors, breakpoints, spacers } from "../styles/variables";
import { useStaticQuery, graphql } from "gatsby";

interface FooterQueryProps {
  cms: {
    entry: {
      adresseLine1: string;
      adresseLine2: string;
      email: string;
      telefon: string;
    };
  };
}

const FooterDiv = styled.footer`
  background: ${colors.brand};
  color: white;
  padding: ${spacers.s}px;
  text-transform: uppercase;

  a {
    color: white;
  }

  @media screen AND (min-width: ${breakpoints.m}px) {
    display: grid;
    grid-template-columns: 200px 1fr;

    > div:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      text-align: right;
    }
  }

  @media screen AND (min-width: ${breakpoints.l}px) {
    > div:nth-of-type(2) {
      flex-direction: row;

      justify-content: flex-end;

      > div {
        margin-right: ${spacers.s}px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
`;

const Footer: React.FC = () => {
  const data: FooterQueryProps = useStaticQuery(graphql`
    query {
      cms {
        entry(type: "sitedata") {
          ... on CMS_andereInhalte_sitedata_Entry {
            adresseLine1
            adresseLine2
            email
            telefon
          }
        }
      }
    }
  `);
  const meta = data.cms.entry;
  return (
    <FooterDiv>
      <div>
        <p>NIEDEREGGER AG</p>
      </div>
      <div>
        <div>
          <p>{meta.adresseLine1}</p>
        </div>
        <div>
          <p>{meta.adresseLine2}</p>
        </div>
        <div>
          <a href={"tel:" + meta.telefon}>{meta.telefon}</a>
        </div>
        <div>
          <a href={"mailto:" + meta.email}>{meta.email}</a>
        </div>
      </div>
    </FooterDiv>
  );
};

export default Footer;
