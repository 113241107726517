import React from "react";
import styled from "styled-components";
import TitleLink from "./TitleLink";
import SectionLinkList from "./SectionLinkList";

interface GridSectionProps {
  data: {
    uri: string;
    title: string;
    children: [
      {
        title: string;
        uri: string;
      }
    ];
  };
  navIsOpen: boolean;
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SectionDiv = styled.div``;

const GridSection: React.FC<GridSectionProps> = ({
  data,
  navIsOpen,
  setNavIsOpen,
}) => (
  <SectionDiv>
    <TitleLink uri={data.uri} navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen}>
      {data.title}
    </TitleLink>
    {data.children.length > 0 ? (
      <SectionLinkList
        navIsOpen={navIsOpen}
        setNavIsOpen={setNavIsOpen}
        data={data.children}
      ></SectionLinkList>
    ) : (
      ""
    )}
  </SectionDiv>
);

export default GridSection;
